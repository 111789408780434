import React from "react";
import PropTypes from "prop-types";
import SEO from "./seo";

import "normalize.css";
import "../style/index.scss";


const Layout: React.FunctionComponent = ({ children }) => (
  <>
    <SEO/>
    <main>{children}</main>
    <footer>Copyright © {new Date().getFullYear()} Melker Veltman</footer>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
